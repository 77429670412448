import { Avatar, Breadcrumb, Layout, Menu, Typography } from 'antd';
import { HomeOutlined, SettingOutlined } from '@ant-design/icons';
import './App.css';

const { Header, Footer, Sider, Content } = Layout;
const { Title } = Typography;

function App() {
  return (
    <div className="App">
      <Layout >        
        <Header style={{padding:10}} className='bg-color-info'>
          <Avatar style={{float:'right'}} src='./logo.png'></Avatar>
          <Title style={{color: 'white'}} level={3}>Bingogo</Title>
        </Header>
          <Layout>
            <Sider theme='light'>
              <Menu mode='inline' defaultSelectedKeys={['1']}>
                <Menu.Item key="1" icon={<HomeOutlined />}>
                  Home
                </Menu.Item>
                <Menu.Item key="2" icon={<SettingOutlined />}>
                  Keepa Synchronization
                </Menu.Item>
              </Menu>
            </Sider>
            <Layout>
              <Content style={{ padding: '0 50px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                  <Breadcrumb.Item>Home</Breadcrumb.Item>
                  <Breadcrumb.Item>Keepa Synchronization</Breadcrumb.Item>
                </Breadcrumb>
                <div style={{ background: '#fff', padding: 24, minHeight: 580 }}>Content</div>
              </Content>
              <Footer>Footer</Footer>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
}

export default App;
